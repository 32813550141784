import store from "@/store";

/** @type { import("vue").ComponentOptions }    */
export default {
    computed: {
        user_details() {
            return store.getters["user/getUserDetails"];
        },
    },
}
